@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;700&display=swap');



body {
  margin: 0;
  font-family: 'Chakra Petch', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

code {
  font-family: 'Chakra Petch', sans-serif;
}

h1, h2, h3 {
  font-family: 'Chakra Petch', sans-serif !important;
}

@keyframes pulseBoxShadow {
  0%, 100% {
    box-shadow: 0 0 3px yellow;
  }
  50% {
    box-shadow: 0 0 28px yellow;
  }
}

::-webkit-scrollbar {
  width: 17px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 0px;
}

::-webkit-scrollbar-track {
  background-color: yellow;
}

* {

  scrollbar-color: yellow black;
}

.sendx-modal-right-4Mffd4fx9FolIjnKxtWptH {
  width: 450px!important;
}

.sendx-modal-right-4Mffd4fx9FolIjnKxtWptH {
  width: 450px !important;
}

input#SubscriberForm-Email-4Mffd4fx9FolIjnKxtWptH {
  border: 1px solid white!important;
}

.powered-by-sendx-content-4Mffd4fx9FolIjnKxtWptH {
  display: none!important;
  visibility: hidden!important;
}

.sendx-modal-title-4Mffd4fx9FolIjnKxtWptH {
  text-align: center;
  padding: 10px;
}

.sendx-modal-4Mffd4fx9FolIjnKxtWptH .sendx-modal-title-4Mffd4fx9FolIjnKxtWptH{
  color: white!important;
}

@media screen and (max-width: 1600px) {
  .Box {
    width: 70% !important;
  }
  .Heading {
    font-size: 4.5em !important;
    margin-top:0px !important;
  }
}

@media screen and (max-width: 1500px) {
  .Box {
    width: 70% !important;
  }  
  .Heading {
    font-size: 3em !important;
    margin-top:0px !important;
    line-height: 3rem !important;
  }
}

@media screen and (max-width: 1300px) {
  .Box {
    width: 70% !important;
  }    
  .Heading {
    font-size: 2.7em !important;
    margin-top:0px !important;
    line-height: 2.7rem !important;
  }
}

@media screen and (max-width: 768px) {
  .Box {
    width: 70% !important;
  }    
  .Heading {
    font-size: 2em !important;
    margin-top:2.5em !important;
    line-height: 2rem !important;
  }
}

@media screen and (max-width: 500px) {
  .Box {
    width: 90% !important;
  }    
  .Heading {
    font-size: 2.5em !important;
    margin-top: 3.5em !important;
    line-height: 2.5rem !important;
  }
}

@media screen and (max-width: 400px) {
  .Box {
    width: 90% !important;
  }    
  .Heading {
    font-size: 2.3em !important;
    margin-top: 3em !important;
    line-height: 2.3rem !important;
  }
}

@media screen and (max-width: 360px) {
  .Box {
    width: 90% !important;
  }    
  .Heading {
    font-size: 2em !important;
    margin-top:0.5em !important;
    line-height: 2rem !important;
  }
}